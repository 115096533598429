<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <v-select
              v-model="settings.partner_id"
              :items="partners"
              item-text="title"
              item-value="id"
              placeholder="Партнёрка"
              label="Партнёрка"
              outlined
            >

            </v-select>
            <v-menu
              ref="orderMenu"
              v-model="orderMenu"
              :close-on-content-click="false"
              :return-value.sync="orderMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="settings.dateOrder"
                  label="Дата создания заказа"
                  :prepend-icon="mdiCalendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:append-outer>
                    <v-icon color="error" @click="settings.dateOrder=null">{{mdiBackspace}}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="settings.dateOrder"
                no-title
                scrollable
                range
              >
              </v-date-picker>
            </v-menu>
            <v-menu
              ref="orderMenu"
              v-model="confirmMenu"
              :close-on-content-click="false"
              :return-value.sync="confirmMenu"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="settings.dateComplete"
                  label="Дата завершения заказа"
                  :prepend-icon="mdiCalendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                >
                  <template v-slot:append-outer>
                    <v-icon color="error" @click="settings.dateComplete=null">{{mdiBackspace}}</v-icon>
                  </template>
                </v-text-field>
              </template>
              <v-date-picker
                v-model="settings.dateComplete"
                no-title
                scrollable
                range
              >
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="settings.ali_id"
              placeholder="Номер заказа AliExpress"
              label="Номер заказа AliExpress"
              outlined
              @change="getPostbacks()"
            >
              <template v-slot:append-outer>
                <v-icon color="error" @click="settings.ali_id=null">{{mdiBackspace}}</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="settings.title"
              placeholder="Название товара"
              label="Название товара"
              outlined
              @change="getPostbacks()"
            >
              <template v-slot:append-outer>
                <v-icon color="error" @click="settings.title=null">{{mdiBackspace}}</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="settings.ali_link"
              placeholder="Ссылка на товар"
              label="Ссылка на товар"
              outlined
              @change="getPostbacks()"
            >
              <template v-slot:append-outer>
                <v-icon color="error" @click="settings.ali_link=null">{{mdiBackspace}}</v-icon>
              </template>
            </v-text-field>
            <v-text-field
              v-model="settings.ali_product_id"
              placeholder="ID товара"
              label="ID товара"
              outlined
              @change="getPostbacks()"
            >
              <template v-slot:append-outer>
                <v-icon color="error" @click="settings.ali_product_id=null">{{mdiBackspace}}</v-icon>
              </template>
            </v-text-field>
            <v-btn color="primary" @click="getPostbacks()">
              Запросить
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card>
          <v-card-text>
            <h3>Статус:</h3>
            <v-checkbox v-model="settings.statuses" multiple value="new" label="new"></v-checkbox>
            <v-checkbox v-model="settings.statuses" multiple value="waiting" label="waiting"></v-checkbox>
            <v-checkbox v-model="settings.statuses" multiple value="completed" label="completed"></v-checkbox>
            <v-checkbox v-model="settings.statuses" multiple value="rejected" label="rejected"></v-checkbox>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <v-select
              v-model="columns"
              :items="headers"
              item-text="text"
              item-value="value"
              placeholder="Колонки"
              label="Колонки"
              multiple
              outlined
              return-object
            >
            </v-select>
          </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <v-card class="mt-3">
          <v-card-text>
            <v-text-field v-model="settings.sub" label="SUB" placeholder="SUB" outlined></v-text-field>
            <v-text-field v-model="settings.sub1" label="SUB 1" placeholder="SUB 1" outlined></v-text-field>
            <v-text-field v-model="settings.sub2" label="SUB 2" placeholder="SUB 2" outlined></v-text-field>
            <v-text-field v-model="settings.sub3" label="SUB 3" placeholder="SUB 3" outlined></v-text-field>
            <v-text-field v-model="settings.sub4" label="SUB 4" placeholder="SUB 4" outlined></v-text-field>
            <v-text-field v-model="settings.sub5" label="SUB 5" placeholder="SUB 5" outlined></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-data-table
            :headers="columns"
            :items="postbacks"
            :items-per-page="30"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:item.status="{item}">
              <v-chip
                :color="item.status==='COMPLETED' ? 'success' : item.status==='REJECTED' ? 'error' : 'info'"
                dark
              >
                {{item.status}}
              </v-chip>
            </template>
            <template v-slot:item.commission_percent="{ item }">
              <v-chip
                :color="getCommissionChipColor(item)"
                dark
              >
                {{ getCommissionPercent(item) }} %
              </v-chip>
            </template>
            <template v-slot:item.commission="{ item }">
              {{Number(item.commission).toFixed(2)}}
            </template>
            <template v-slot:item.order_sum="{ item }">
              {{Number(item.order_sum).toFixed(2)}}
            </template>
            <template v-slot:item.price="{ item }">
              {{Number(item.price).toFixed(2)}}
            </template>
            <template v-if="postbacks.length" slot="body.prepend">
              <tr>
                  <th class="title" v-for="(header, i) in headers"
                      :key="i"> <span v-if="header.summable">{{ sumField(header.value) }}</span></th>
              </tr>
            </template>

            <template v-if="postbacks.length" slot="body.append">
              <tr>
                <th class="title" v-for="(header, i) in headers"
                    :key="i"> <span v-if="header.summable">{{ sumField(header.value) }}</span></th>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mdiCalendar, mdiBackspace} from '@mdi/js'
import QueryString from 'qs'
import httpClient from '@/services/http.service'

export default {
  name: 'ShowPostbacks',
  data(){
    return{
      mdiCalendar, mdiBackspace,
      orderMenu: false,
      confirmMenu: false,
      settings: {
        dateOrder: null,
        dateComplete: null,
        partner_id: 1,
      },
      headers: [{text: 'Ссылка', value: 'ali_product_url', summable: false}, {text: 'Номер заказа', value: 'ali_id', summable: false}, {text: 'Статус', value: 'status', summable: false}, {text: 'Название товара', value: 'title', summable: false}, {text: 'Цена', value: 'price', summable: true}, {text: 'Сумма заказа', value: 'order_sum', summable: true},
        {text: 'Комиссия', value: 'commission', summable: true}, {text: 'Комиссия %', value: 'commission_percent', summable: false}, {text: 'Доход база', value: 'commission_basic', summable: true}, {text: 'Доход бонус', value: 'commission_bonus', summable: true}, {text: 'Дата заказа', value: 'order_time', summable: false}, {text: 'SUB', value: 'sub', summable: false}],
      columns: this.headers,
      postbacks: [],
      loading: false,
    }
  },
  computed:{
    partners(){
      return JSON.parse(JSON.stringify(this.$store.state.partners))
    }
  },
  created() {
    this.columns = this.headers
  },
  methods:{
    getPostbacks(){
      this.loading = true
      httpClient.post('reports/postbacks-live', QueryString.stringify(this.settings)).then(({data})=>{
        this.loading = false
        if (data.success){
          this.postbacks = data.postbacks
        }else{
          this.postbacks = []
        }
      })
    },
    sumField(key) {
      // sum data in give key (property)
      const sum = Math.round((this.postbacks.reduce((a, b) => Number(a) + Number((b[key] || 0)), 0))*100)/100
       return sum.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
    },
    getCommissionPercent(item){
      return Number(item.commission / item.order_sum * 100).toFixed(2)
    },
    getCommissionChipColor(item){
      const percent = Number(item.commission / item.order_sum * 100)
      if (percent < 1){
        return 'error'
      }
      if (percent >= 1 && percent < 5){
        return 'info'
      }
      return 'success'
    }
  }
}
</script>

<style scoped>

</style>
